import { useCallback, useEffect, useRef, useState } from "react";
import KixLogo from "../assets/images/kix-market-logo-white.png";

const Counter = () => {
  const [countDownTime, setCountDownTime] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const secondTimer = useRef(null);
  const COUNTDOWN_END_DATE = new Date("December 15, 2024 00:00:00").getTime();

  const getTimeDifference = (countDownDate) => {
    const currentTime = new Date().getTime();
    const timeDifference = countDownDate - currentTime;

    const days = Math.floor(timeDifference / (24 * 60 * 60 * 1000));
    const hours = Math.floor((timeDifference % (24 * 60 * 60 * 1000)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (60 * 60 * 1000)) / (1000 * 60));
    const seconds = Math.floor((timeDifference % (60 * 1000)) / 1000);

    if (timeDifference < 0) {
      if (secondTimer.current) {
        secondTimer.current.className = "relative top-5";
      }
      setCountDownTime({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      });
      clearInterval();
    } else {
      if (secondTimer.current) {
        secondTimer.current.className = "animate-timer";
      }
      setCountDownTime({
        days,
        hours,
        minutes,
        seconds,
      });
    }
  };

  const startCountDown = useCallback(() => {
    setInterval(() => {
      getTimeDifference(COUNTDOWN_END_DATE);
    }, 1000);
  }, []);

  useEffect(() => {
    startCountDown();
  }, [startCountDown]);

  return (
    <div className="bg-black h-screen w-screen flex items-center justify-center">
      <div className="flex flex-col items-center">
        <div className="mb-20">
          <img src={KixLogo} className="w-80" alt="Kix Market Logo" />
        </div>
        <div className="flex gap-3 sm:gap-1 flex-row bg-black border-2 border-solid border-[#04C5A8] h-36 rounded-lg overflow-hidden pt-2 pr-3 sm:pt-0 sm:pr-0">
          <div className="flex flex-col bg-black sm:w-32 w-16">
            <div className="h-16 sm:h-20 bg-black">
              <div className="h-[60px] flex justify-center bg-black sm:text-3xl text-xl !text-white">
                <div
                  className={
                    countDownTime?.days >= 0 &&
                    countDownTime?.hours === 23 &&
                    countDownTime?.minutes === 59 &&
                    countDownTime?.seconds === 59
                      ? "animate-timer"
                      : "relative top-5"
                  }
                >
                  {countDownTime?.days}
                </div>
              </div>
            </div>
            <div className="flex justify-center">
              <span className="text-lg sm:text-2xl text-center text-white">
                {countDownTime?.days === 1 ? "Day" : "Days"}
              </span>
            </div>
          </div>
          <div className="flex flex-col bg-black sm:w-32 w-16">
            <div className="h-16 sm:h-20 bg-black">
              <div className="h-[60px] flex justify-center bg-black sm:text-3xl text-xl !text-white">
                <div
                  className={
                    countDownTime?.hours >= 0 &&
                    countDownTime?.minutes === 59 &&
                    countDownTime?.seconds === 59
                      ? "animate-timer"
                      : "relative top-5"
                  }
                >
                  {countDownTime?.hours}
                </div>
              </div>
            </div>
            <div className="flex justify-center">
              <span className="text-lg sm:text-2xl text-center text-white">
                {countDownTime?.hours === 1 ? "Hour" : "Hours"}
              </span>
            </div>
          </div>
          <div className="flex flex-col bg-black sm:w-32 w-16">
            <div className="h-16 sm:h-20 bg-black">
              <div className="h-[60px] flex justify-center bg-black sm:text-3xl text-xl !text-white">
                <div
                  className={
                    countDownTime?.minutes >= 0 && countDownTime?.seconds === 59
                      ? "animate-timer"
                      : "relative top-5"
                  }
                >
                  {countDownTime?.minutes}
                </div>
              </div>
            </div>
            <div className="flex justify-center">
              <span className="text-lg sm:text-2xl text-center text-white">
                {countDownTime?.minutes === 1 ? "Minute" : "Minutes"}
              </span>
            </div>
          </div>
          <div className="flex flex-col bg-black sm:w-32 w-16">
            <div className="h-16 sm:h-20 bg-black">
              <div className="h-[60px] flex justify-center bg-black overflow-hidden sm:text-3xl text-xl text-white">
                <div ref={secondTimer}>{countDownTime?.seconds}</div>
              </div>
            </div>
            <div className="flex justify-center">
              <span className="text-lg sm:text-2xl text-center text-white">
                {countDownTime?.seconds === 1 ? "Second" : "Seconds"}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Counter;
