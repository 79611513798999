import Counter from "./Pages/Counter";
function App() {
  return (
    <div className="App">
      <Counter/>
    </div>
  );
}

export default App;
